import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import Page from './page/page-layout'
import Layout from '../layout'
import GoHome from '../gohome.js'

const News = (props) => {
  const lang = props.pageContext.lang
  var filteredNews = []
  const news = props.data.allContentfulNews.nodes
  news.map((item) => {
    if (item.node_locale.substr(0, 2) === lang) filteredNews.push(item)
  })

  const Info = styled.div`
    width: 16vw;
    margin: 60px auto 20px auto;
    padding-bottom: 30px;
    font-weight: 300;
  `
  const SideText = styled.p`
    font-size: 1rem;
    font-family: 'Nunito';
    margin-inline-start: auto;
  `
  const side = (
    <Info>
      <SideText></SideText>
    </Info>
  )

  const Header = styled.header`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row;
  `
  const Title = styled.h1`
    font-family: 'Montserrat';
    font-weight: 700;
    color: black;
    flex: 3;
    margin: 0;
    z-index: 100;
    position: relative;
    font-size: 2.5rem;
    @media (min-width: 1400px) {
      font-size: 2.8rem;
    }
    @media (min-width: 1600px) {
      font-size: 3.4rem;
    }
    @media (max-width: 1200px) {
      font-size: 2.3rem;
    }
    @media (max-width: 1100px) {
      font-size: 2.2rem;
      flex-direction: column;
    }
  `
  const Content = styled.div`
    flex: 1;
    flex-grow: unset;
    line-height: 170%;
    font-weight: 300;
    font-family: 'Montserrat';
    font-size: 1.2rem;
    @media (min-width: 1400px) {
      font-size: 1.4rem;
    }
    @media (min-width: 1600px) {
      font-size: 1.7rem;
    }
    @media (max-width: 1200px) {
      font-size: 1.1rem;
    }
    @media (max-width: 1100px) {
      font-size: 1rem;
      flex-direction: column;
    }
    @media (max-width: 700px) {
      line-height: 130%;
    }
  `
  const New = styled.dl`
    font-weight: 300;
    font-family: 'Montserrat';
    font-size: 1.1vw !important;
  `
  const Date = styled.dt`
    @media (max-width: 700px) {
      font-size: 11px !important;
    }
  `
  const Text = styled.dd`
    @media (max-width: 700px) {
      font-size: 11px !important;
    }
  `
  const main = (
    <>
      <GoHome {...{ lang }} />
      <Header>
        <Title>News</Title>
      </Header>
      <Content className="news">
        {filteredNews &&
          filteredNews.map((item) => {
            return (
              <New>
                <Date>{item.date}</Date>
                <Text
                  dangerouslySetInnerHTML={{
                    __html: item.detail && item.detail.childMarkdownRemark.html,
                  }}
                />
              </New>
            )
          })}
      </Content>
    </>
  )

  return (
    <Layout title="News - Andrea Martines" description="" otherLangSlug="news">
      <Page {...{ side }} {...{ main }} />
    </Layout>
  )
}
export default News

export const pageQuery = graphql`
  query NewsQuery1 {
    allContentfulNews(sort: { fields: date, order: DESC }) {
      nodes {
        date(formatString: "DD/MM/YYYY")
        brief {
          childMarkdownRemark {
            html
          }
        }
        detail {
          childMarkdownRemark {
            html
          }
        }
        category
        node_locale
      }
    }
  }
`
